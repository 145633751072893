<template>
	<div class="buttonCounterComponent">
		<div class="flex text-gray-500 justify-end items-center">
			<button
				@click="decrement"
				class="button hoverEffects">
				<span class="icon">−</span>
			</button>
			<p
				class="mx-3 w-4 text-center"
				:class="textStyle">
				{{ count }}
			</p>
			<button
				@click="increment"
				:disabled="maxReached"
				class="button hoverEffects">
				<span class="icon">+</span>
			</button>
		</div>
	</div>
</template>

<script setup>
	const { initialValue, min, max } = defineProps({
		textStyle: {
			type: String,
			default: "",
		},
		initialValue: {
			type: Number,
			default: 0,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 9999,
		},
	})
	const emit = defineEmits(["changed"])
	const count = ref(initialValue)
	const maxReached = ref(count.value === max)

	const increment = () => {
		changed(count.value + 1)
	}

	const decrement = () => {
		changed(count.value - 1)
	}

	const changed = (val) => {
		//Enforce min value
		if (val < min || val === "" || val === null) {
			return
		}

		//Enforce max value
		maxReached.value = val + 1 >= max
		emit("changed", { value: val })
	}
</script>

<style scoped>
	.button {
		@apply rounded-full cursor-pointer pb-1 w-8 h-8 border border-gray-200 disabled:bg-gray-200;
		@apply bg-white hover:text-gray-700 hover:bg-gray-400;
	}

	.icon {
		@apply text-xl font-light;
	}
</style>
