<template>
	<ButtonGeneric
		:id="id"
		class="button-secondary"
		@clicked="clicked">
		<slot />
	</ButtonGeneric>
</template>

<script setup>
	const { id } = defineProps({
		id: {
			type: String,
			default: "",
		},
	})

	const emits = defineEmits(["clicked"])
	const clicked = () => {
		emits("clicked", { id: id })
	}
</script>

<style lang="scss">
	.button-secondary {
		@apply text-btn-secondary-text bg-btn-secondary-bg hover:text-btn-secondary-bg hover:bg-btn-secondary-text;
	}
</style>
