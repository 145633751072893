/*
Returns a listener method that will detect of a click has occurred outside of the component ref

Params:
component: Use a Vue Ref on the component that you want to control
ignoreIds: Provide a String[] of id's of elements that should be ignored (ie. the opening btn)
callback: The function to call if a click has happened outside of the component or ignoreIds
 */
export default function useDetectOutsideClick(component, ignoreIds, callback) {
	if (!component) return
	const listener = (event) => {
		const clickedComponent = event.target !== component.value && event.composedPath().includes(component.value)
		let clickedIgnorableEl = false

		ignoreIds.forEach((id) => {
			clickedIgnorableEl |= event.composedPath().includes(document.getElementById(id))
		})

		if (clickedComponent || clickedIgnorableEl) {
			return
		}

		if (typeof callback === "function") {
			callback()
		}
	}

	return listener
}
